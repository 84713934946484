import React from "react";

const PageLoader = () => {
  return (
    <div class="page-loader">
      <div class="page-circle"></div>
      <div class="page-circle"></div>
      <div class="page-circle"></div>
      <div class="page-circle"></div>
      <div class="page-circle"></div>
    </div>
  );
};

export default PageLoader;
